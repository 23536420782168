import React from "react";
import HeroImage from "../components/heroImage";
import CaveImg from "../assets/iNeedToGo/cave1.jpg";

export default function projects() {
  return (
    <div>
      <HeroImage />
      <div className="flex items-start md:items-start justify-center h-[70vh] ">
        <div className="bg-slate-800 bg-opacity-60 rounded-lg text-center">
          <div className="text-thisGray text-headSize2 font-Heading">
            I need to go!
          </div>
          <div className="text-paragraphSize1 font-Paragraph text-thisGreen">
            A nostalgic point-and-click game.
          </div>
          <div className="relative">
            <div className="absolute text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] font-Heading text-headSize3 bg-slate-900 bg-opacity-30 rounded">
              IN PROGRESS
            </div>
            <img
              src={CaveImg}
              alt="just a cave"
              className="h-[400px] p-4 rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
