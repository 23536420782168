import React from "react";
import Hero from "../components/hero";
import Company from "../components/company";
import Johan from "../components/johan";
import FadeInSection from "../components/FadeInSection";


export default function home() {

  return (
    <div>
      <div className="max-h-[1200px] overflow-hidden">
        <Hero />
      </div>
      <div className="max-h-[1200px] overflow-hidden 2xl:mt-10">
        <FadeInSection>
          <Company />
        </FadeInSection>
      </div>
      <div className="max-h-[1200px] overflow-hidden 2xl:mt-10">
        <FadeInSection>
          <Johan />
        </FadeInSection>
      </div>
    </div>
  );
}
