import React from "react";
import HeroImg from "../components/heroImage";
import { Link } from "react-router-dom";
import JohanNordicGame from "../assets/johan_nordicgame2.jpg";

export default function contact() {
  let lable = "johan@aldgard.se";
  let mailto = "mailto:no-reply@example.com";

  return (
    <div>
      <HeroImg />
      <div className="flex flex-col gap-40 items-center">
        <div className="text-center text-thisGray">
          For business inquiries: <br />
          <br />
          <div>
            <Link
              className="border-[1px] border-white border-opacity-40 text-slate-200 bg-white bg-opacity-10 p-2 rounded"
              to="#"
              onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
              }}
            >
              {lable}
            </Link>
          </div>
        </div>
      <div className="">
        <img 
          src={JohanNordicGame}
          className="max-w-[600px] rounded-full brightness-90" 
          alt="Johan at Nordic Game 2024"/>
      </div>
      </div>
    </div>
  );
}
