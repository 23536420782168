import React from "react";
import Forest from "../assets/forest_test.jpeg";
import ProfilePic from "../assets/JohanRounded2.png";

const Johan = () => {
  return (
    <div className="relative mt-80">
      <div className="text-headSize1 font-Heading text-white text-center leading-[4.5rem] absolute z-10 left-[50%] -top-[1%] -translate-x-[50%] -translate-y-[50%]">
        “an experience should <br></br>not be compromised”
      </div>
      <div className="-z-10">
        <img
          src={Forest}
          alt="forest"
          className="sm:w-[100%] h-[140vh] object-cover brightness-[0.5] contrast-100"
        ></img>
      </div>
      <div className="absolute sm:top-[15%] top-[20%] left-[50%] -translate-x-[50%] flex sm:flex-row flex-col items-center sm:gap-[200px] gap-24">
        <div>
          <img
            src={ProfilePic}
            alt="johan"
            className="sm:max-h-[400px] max-h-[220px]"
          ></img>
        </div>
        <div className="flex flex-col sm:w-[600px] w-[90vw] gap-6 text-paragraphSize1 font-Paragraph text-white">
          <div>
            Johan, a dedicated music university graduate, has enriched several
            projects with his compositions and shared his talents across Europe
            with multiple bands.
          </div>
          <div>
            His self-driven learning in programming, business strategy, and
            marketing, along with his involvement in video game development and
            voice acting, showcases his versatile skills and commitment to
            creative expression.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Johan;
