import React from "react";
import Bridge from "../assets/bridge2.webp";

const Company = () => {
  return (
    <div className="relative sm:mt-80 mt-40">
      <div className="sm:text-headSize1 text-headSize1Mobile font-Heading text-white text-center w-[100vw] leading-[4.5rem] absolute z-10 left-[50%] -top-[2.5%] -translate-x-[50%] -translate-y-[50%]">
        fostering art,<br></br>
        empowering studios
      </div>
      <div className="-z-10">
        <img
          src={Bridge}
          alt="bridge"
          className="sm:w-[100%] h-[100vh] object-cover brightness-[0.45]"
        ></img>
      </div>
      <div className="flex flex-col sm:w-[600px] w-[90vw] justify-center gap-6 text-paragraphSize1 font-Paragraph text-white absolute sm:top-[15%] sm:left-[20%] top-[15%] left-[5vw]">
        <div>
          Our vision is to <b>bridge</b> the worlds of business, art, and
          service in video game development, all while empowering other studios
          to thrive.
        </div>
        <div>
          We aspire to craft games that inspire and make a difference, while
          also providing strategic guidance to fellow developers. Rooted in a
          culture of love and dedication, we aim to set new industry standards
          and help studios realize their full potential.
        </div>
      </div>
    </div>
  );
};

export default Company;
