import React from "react";
import HeroImg from "./heroImage"

const FormNewsletter = () => {
  return (
    <div className="">
      <HeroImg/>
      <div className="flex items-center justify-center">
        <div className="text-white">
          <iframe
          className="md:max-w-[540px] w-[90vw]"
            width="540"
            height="1097"
            src="https://8b0be12e.sibforms.com/serve/MUIFAGKq8YubLZUtw3KiCT758xs_t-emJEHsV1PdrKp6cxmWXdELInn8GVB8FlBGkIyLMh2-pxRZq7zwkniD4tlVuHTrALMiYNPirq7ZahR51QEueefyoFUSxA01UczCSgNABgCmxEI23RxTbncVguse1bhZx1jmen9V_9hMUyTC95YXr2dadjaARrY-WeYf24ppwiSpRN1JpcPI"
            frameBorder="0"
            title="newsletter form"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default FormNewsletter;
