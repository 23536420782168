import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

export default function MenuButton() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Menu open={openMenu} handler={setOpenMenu} placement="bottom-start">
      <MenuHandler>
        <Button>{openMenu ? "\\ \\ \\" : "/ / /"}</Button>
      </MenuHandler>
      <MenuList className="bg-opacity-90 bg-slate-900 flex flex-col gap-6 p-2 rounded-md text-white">
        <MenuItem><Link to="/">home</Link></MenuItem>
        <MenuItem><Link to="/newsletter">newsletter</Link></MenuItem>
        {/* <MenuItem><Link to="/game-test">game testing</Link></MenuItem> */}
        <MenuItem><Link to="/projects">projects</Link></MenuItem>
        <MenuItem><Link to="/contact">contact</Link></MenuItem>
      </MenuList>
    </Menu>
  );
}
