import React from "react";
import { Tooltip } from "@material-tailwind/react";

export default function footer() {
  return (
    <div className="grid grid-cols-3 content-center text-center h-40 text-thisGray items-center font-Paragraph text-paragraphSize2">
      <Tooltip content="johan@aldgard.se" className="text-thisGray" interactive={true}>
        <div>Email</div>
      </Tooltip>
      <div>Made by Aldgard Studio</div>
      <div>Copyright. All rights reserved.</div>
    </div>
  );
}
