import React from "react";
import HeroImg from "./heroImage";

const Hero = () => {
  return (
    <div className="text-gray-100 sm:h-[100vh] h-[80vh] overflow-hidden flex flex-col">
      <HeroImg />
      <div>
        <div className="text-center sm:mt-[18vh] mt-[6vh]">
          <div className="font-Heading text-headSize1 hidden sm:block">
            inspiring video games
          </div>
          <div className="font-Heading text-headSize1 block sm:hidden">
            inspiring <br></br>video games
          </div>
          <div className="font-Paragraph text-paragraphSize2 mt-6">
            a vision by Johan Aldgård
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-center max-w-[80vw] flex flex-col gap-4">
            <div className="lg:mt-40">______________</div>
            <div className="font-Paragraph text-paragraphSize2 text-thisGreen">
              imagine yourself in a land not yet known, where secrets and
              wonders are sown. <br></br>
              feel emotions that stay, even as the screen fades away.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

//text-center flex flex-col gap-10 absolute left-[50%] 2xl:top-[75%] top-[80%] -translate-x-[50%] -translate-y-[50%]
