import React from "react";
import MagicalForest from "../assets/BackgroundHero3v2.png";
//import UseWindowDimensions from "../hooks/useWindowDimensions";

export default function heroImage() {
  //const { width } = UseWindowDimensions();
  

  return (
    <div className="-z-10 absolute -top-10">
        <img
          src={MagicalForest}
          alt="background of a mystical forest"
          className="sm:w-[100vw] h-[100vh] object-cover brightness-[0.40]"
          ></img>
      </div>
  );
}
