import React from "react";
import Logo from "../assets/aldgard-logo.png";
import { Link } from "react-router-dom";
import MenuButton from "./menuButton";

const Header = () => {
  return (
    <div>
      <div className="text-gray-200 flex justify-between relative h-52 items-center">
        <div className="pl-24 absolute flex gap-10 font-Paragraph text-paragraphSize2 invisible lg:visible">
          <span>
            <Link to="/">home</Link>
          </span>
          <span>
            <Link to="/newsletter">newsletter</Link>
          </span>
          {/* <span><Link to="/game-test">game testing</Link></span> */}
          <span>
            <Link to="/projects">projects</Link>
          </span>
          <span>
            <Link to="/contact">contact</Link>
          </span>
        </div>
        <div className="grow text-center">
          <Link to="/">
            <img
              src={Logo}
              alt="just a logo"
              className="h-14 block ml-auto mr-auto"
            ></img>
          </Link>
        </div>
        {/* <div className="text-gray-200 flex h-52 items-center">
      <div className="grow text-center">
         <img
           src={Logo}
           alt="just a logo"
           className="h-14 block ml-auto mr-auto"
         ></img>
       </div> */}
        <div className="w-audo absolute left-10 top-6 visible lg:invisible">
          <MenuButton />
        </div>
      </div>
    </div>
  );
};

export default Header;
