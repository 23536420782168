import React from "react";
import { useState, useRef, useEffect } from "react";

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    if (!domRef) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => {
      if (domRef.current) observer.unobserve(domRef.current);
    };
  }, []);
  return (
    <div
      className={`fade-in-section text-white ${
        isVisible
          ? "visible opacity-100 transform-none transition duration-700 ease-in-out"
          : "invisible opacity-0 translate-y-[10vh]"
      }`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeInSection;
