import React from "react";
import HeroImg from "./heroImage"

const FormGameTest = () => {
  return (
    <div className="">
      <HeroImg/>
      <div className="flex items-center justify-center">
        <div className="text-white">
          <iframe
          className="md:max-w-[540px] w-[90vw]"
            width="540"
            height="1200"
            src="https://8b0be12e.sibforms.com/serve/MUIFAGkvKptV6ykEwWXUXSibVhGCEhw6rWzeT4xn8M-QzlS8LGfKjIAUPrg42rLfgw6fDb5MnENctTLOq1z8SRe7G1zM8VtNwGvjlwWCKKnxSQr8Vn1sUsPLvIhxtXLat3WyoHfqXAxhBWBB7D0I2aTmnAsQ5dwGAeOO_B4E3Ap9MNOfdoKZUzqnDaWc_OnjMQK28bxH4MXXIQ5W"
            frameBorder="0"
            title="game test form"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default FormGameTest;
